import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import { FORM_ERROR } from 'final-form'

export default class Wizard extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired
  }
  static Page = ({ children }) => children

  constructor(props) {
    super(props)

    let pageTemp = parseInt(props.page);

    //console.log(props.custompage + '-' + props.page)
    /*if(props.custompage===2) {
      if(props.page>=4) 
        pageTemp = pageTemp+3;
      else if(props.page>0)
        pageTemp = pageTemp+1;
    } else {
      if(props.page>=4) 
        pageTemp = pageTemp+2;
      else if(props.page>0)
        pageTemp = pageTemp+1;
    }*/

    this.state = {
      page: pageTemp,
      custompage: props.custompage,
      values: props.initialValues || {}
    }
  }
  componentWillReceiveProps = (tempprops) => {

      /*console.log('in update fn:' + this.state.page + '-' + tempprops.page)
      if(this.state.page!=tempprops.page) {
        this.setState(state => ({
          page: tempprops.page,
          values: tempprops.initialValues || {}
        }))
      }*/
      this.setState(state => ({
        custompage: tempprops.custompage,
       // page: tempprops.page
      }))
  }
  next = values => {
    const totalPage = React.Children.toArray(this.props.children).length;

    //console.log('wizard ' + this.state.page)

    this.setState(state => ({
      page: Math.min(state.page + 1, totalPage - 1),
      values
    }))

    //this.props.myCallBack(values)
  }

  previous = () =>
    this.setState(state => ({
      page: Math.max(state.page - 1, 0)
    }))

  /**
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */

  validate = values => {
    const activePage = React.Children.toArray(this.props.children)[
      this.state.page
    ]

    return activePage.props.validate ? activePage.props.validate(values) : {}
  }

  handleSubmit = values => {

    //console.log('submit')
   // console.log(values)

    const { children, onSubmit } = this.props
    const { page } = this.state
    const isLastPage = page === React.Children.count(children) - this.state.custompage

    // console.log('children' + React.Children.count(children) + '-' + page)

    return onSubmit(values, isLastPage, page).then( (value) => {
      //console.log(value)
      if(value.status===true) {
        /*if(value.skipPage) {
          this.setState(state => ({
            page: state.page + 1
          }))
        }*/

        //console.log('value.currentpage:' + value.currentpage)

       /* this.setState(state => ({
          page: value.currentpage
        }))
        */
        this.next(values)
      } else {
        return { [FORM_ERROR]: value.error}
      }
    })
  
    /*if (isLastPage) {
      const isValid = this.props.validateForm(values);

      if(isValid) {
        this.next(values)
        return onSubmit(values, isLastPage)
      } else {
        return { [FORM_ERROR]: 'Please select atleast one option' }
      }
      
    } else {
      this.next(values)
      return onSubmit(values, isLastPage)
    }*/

  }

  pagination = () => {
    const { page } = this.state
    var totalPages = React.Children.toArray(this.props.children).length-4;

    return <ul className="pagination_list">
        {Array(totalPages).fill(0).map((el, index) =>
          <li className={(index+1)===page ? 'active_pagess' : ''} key={index}></li>
        )}
      </ul>
  }

  render() {
    const { children } = this.props
    const { page, values } = this.state
    const activePage = React.Children.toArray(children)[page]
    const isLastPage = page === React.Children.count(children) - (this.state.custompage-1)
    const isLastSubmitPage = page === React.Children.count(children) - (this.state.custompage)

    //console.log(page + '===' + React.Children.count(children))
   // console.log(React.Children.count(children) + '-' + this.state.custompage)
    // console.log('this.state.custompage' + this.state.custompage)
    // console.log('render page:' + page)
    // console.log(activePage)

    return (
      <Form
        initialValues={values}
        validate={this.validate}
        onSubmit={this.handleSubmit}
      >
        {({ submitError, handleSubmit, submitting, values, invalid }) => (
          <form onSubmit={handleSubmit}>
            {activePage}
            { activePage.props.type!=='Custom' && activePage.props.type!=='userForm' && page<9 &&
              this.pagination()
            }
            {submitError && <div className="error_form_message">{submitError}</div>}
            <div className="buttons">
              {page > 2 && !isLastPage && !isLastSubmitPage && activePage.props.type!=='Custom' && (
                <button type="button" className="btn_prev" onClick={this.previous}>
                  Anterior
                </button>
              )}
              {!isLastPage && !isLastSubmitPage && activePage.props.type!=='Custom' && activePage.props.type!=='userForm' &&  
                <button type="submit" disabled={invalid || submitting} className="btn_orange btn_next">Siguiente</button>}
              {!isLastPage && !isLastSubmitPage && activePage.props.type==='userForm' && 
                <button type="submit" disabled={invalid || submitting} className="btn_orange btn_next">{activePage.props?.nextText}</button>}
              {!isLastPage && !isLastSubmitPage && activePage.props.type==='Custom' && 
                <button type="submit" className="btn_orange btn_next">{activePage.props?.nextText}</button>}
              {isLastSubmitPage && activePage.props.type!=='Completion' && (
                <button type="submit"  className="btn_orange btn_submit" disabled={invalid || submitting}>
                  Completa
                </button>
              )}
            </div>
            
          </form>
        )}
      </Form>
    )
  }
}
