import { gql } from "@apollo/client";

const SAVE_FORM_MUTATION
 = gql`
  mutation saveSurveyMutation($clientMutationId: String!, $userdata:String!, $token:String!, $answers: String!, $updateacuser: String! ){
    saveSurvey(input: {clientMutationId: $clientMutationId, userdata:$userdata, token:$token, answers: $answers, updateacuser: $updateacuser}) {
      data
      success
    }
  }
`;

export default SAVE_FORM_MUTATION