import { gql } from "@apollo/client";

const SURVEY_FORM_DATA = gql`query surveyData($id: ID!) {
    survey(id: $id) {
        date
        content
        slug
        title
        id
        surveyForm {
            startPage {
                bannerImage {
                  mediaItemUrl
                  sourceUrl
                }
                startButtonText
                surveyHeading
                surveySubHeading
                surveyTopHeading
              }
            questionAnswer {
                question
                questionNumber
                questionTag
                questionSubHeading
                questionInfo
                styleClass
                answerType
                answers {
                    fieldGroupName
                    text
                    subText
                    imageType
                    step
                    image {
                        sourceUrl
                    }
                }
                bannerImage {
                    mediaItemUrl
                    sourceUrl
                  }
                customContent {
                    customButtonText
                    customHeading
                    customSubheading
                }
                communication {
                    communicationTypeHeading
                    communicationTypeSubheading
                    communicationType {
                        type
                        title
                        typeTag
                        selectType {
                          tag
                          title
                        }
                    }
                }
            }
            fieldGroupName
            completionSectionForInterestedInEvent {
                completionHeading
                completionMessage
                completionVideoHeading
                completionVideo
                completionImage {
                    sourceUrl
                }
              }
              completionSectionForNoEvent {
                completionHeading
                completionMessage
                completionMessage2
              }
        }
    } 
}
`;

export default SURVEY_FORM_DATA;