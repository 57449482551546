import { gql } from "@apollo/client";

const SURVEY_DETAIL = gql`
                    query surveyDetaill($token: String!) {
                        surveyDetail(token: $token) {
                            token
                            surveyid
                            answered_questions
                            total_questions
                            status
                            answers {
                                nodes {
                                    question_tag
                                    answer
                                    
                                }
                            }
                        }
                }`;

export default SURVEY_DETAIL